<script lang="ts" setup>
import type { ImageFileField, LatestTrendsSectionRecord, TrendsInspirationTagRecord, TrendsQuery } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ data: LatestTrendsSectionRecord; currentPageId: string }>();

const localePath = useLocalePath();
const { locale } = useI18n();

const { data: trendsData } = await useFetch<TrendsQuery>("/api/cms/trends", {
  query: { pageSize: props.data.itemCount + 1, page: 1, locale: locale },
});

const filteredTrends = computed(() => {
  return trendsData.value?.allTrendsInspirations?.filter(t => t.id !== props.currentPageId).slice(0, props.data.itemCount);
});
</script>

<template>
  <div v-if="(filteredTrends?.length ?? 0) > 0">
    <h2 class="mb-xl md:mb-3xl text-center">
      {{ data.title }}
    </h2>
    <div class="mb-lg gap-lg md:mb-3xl md:gap-md grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      <article v-for="trend in filteredTrends" :key="trend.id">
        <NuxtLink :to="localePath({ name: 'trends-slug', params: { slug: trend.slug } })">
          <TeaserCard
            :page-id="trend.id"
            :image="trend.teaserImage as ImageFileField"
            :title="trend.title"
            :text="trend.teaserText"
            :tags="trend.tags as TrendsInspirationTagRecord[]"
          />
        </NuxtLink>
      </article>
    </div>
    <div class="flex justify-center">
      <UButton variant="outline" color="primary" size="md" :to="localePath(`/${data.linkPage.slug}`)">
        {{ data.linkText }}
      </UButton>
    </div>
  </div>
</template>

<style scoped>

</style>
